import {reactive, readonly, toRefs} from "vue";
import service from "@/utils/axios";
import {useRoute} from "vue-router";
import {message} from "ant-design-vue";
import {onPageLoadingEnd, onPageLoadingStart} from "@/utils/DLoading";

export interface IRecord{
  teacherName: string;      // 老师姓名
  createTime: string;       //  操作时间
  studentName: string;      //  学生名称
  integralRuleName: string; // 发放类型
  integralCount: number;    //  获得积分数
  integralRecordId: number; //  课堂互动积分记录ID
}

export interface IRecordState{
  tableData: IRecord[];
  total: number;
  pageIndex: number;
  pageSize: number;
}

export function sendRecordBill(){
  let stateRef = reactive(<IRecordState>{
    tableData: [],
    total: 0,
    pageIndex: 1,
    pageSize: 10,
  });

  let staticState = readonly({
    columns: [
      {title: '操作时间', dataIndex: 'createTime', key: 'createTime',align: 'center'},
      {title: '操作老师', dataIndex: 'teacherName', key: 'teacherName',align: 'center'},
      {title: '发放类型', dataIndex: 'integralRuleName', key: 'integralRuleName',align: 'center'},
      {title: '获得积分', dataIndex: 'integralCount', key: 'integralCount',align: 'center'},
      {title: '学生', dataIndex: 'studentName', key: 'studentName',align: 'center'},
      {title: '操作', slots: {customRender: 'opt'}, key: 'operation',align: 'center',},
    ]
  })
  let refData = toRefs(stateRef)

  const route = useRoute();

  const onDelete = async (item: IRecord) =>{
    const r:any = await service.get(`/dwart/an_teaching/classCourse/deleteIntegralRecord/${item.integralRecordId}`)
    console.log(r);
    if (r.result){
      message.success('删除成功');
      await getTable();
    }else{
      message.error(r.message)
    }
  }
  const rowClassName = (record, index) =>{
    if (index%2 === 1){
      return 'table-f9'
    }
  }

  const onPageChange = async (page: number, pageSize: number)=>{
    stateRef.pageIndex = page;
    stateRef.pageSize = pageSize;
    await getTable();
  }

  const onSizeChange = async (current: number, size: number)=>{
    stateRef.pageIndex = current;
    stateRef.pageSize = size;
    await getTable();
  }

  const getTable = async () => {
    onPageLoadingStart()
    const r: any = await service.post('/dwart/an_teaching/classCourse/findStudentRecord', {
      classScheduleId: route.query.scheduleId,
      pageIndex: stateRef.pageIndex,
      pageSize: stateRef.pageSize,
    })

    stateRef.tableData = r.entity;
    stateRef.total = r.count;
    onPageLoadingEnd()
  }

  const init = async () => {
    await getTable();
  }

  return {
    refData,
    staticState,
    onDelete,
    rowClassName,
    onPageChange,
    onSizeChange,
    init,
  }
}
